@tailwind base;

html {
  font-family: "Poppins", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizelegibility;
  font-weight: 400;
  scroll-behavior: smooth;
  font-size: 16px;
}

:focus {
  outline: none !important;
}

button {
  @apply inline-block py-3 px-5 leading-none text-base rounded border font-semibold;
}

.btn-joy {
  @apply text-offwhite bg-joy  border-joy;
}

.btn-joy:hover {
  @apply text-joy bg-white border-joy;
}

.btn-trans-white {
  @apply text-offwhite bg-none  border-white;
}

.btn-trans-white:hover {
  @apply text-black bg-white border-black;
}

/* Form Elements */

.text-box {
  @apply block w-full max-h-10 py-2 px-4 text-gray-700 bg-gray-200 border border-gray-200 rounded;
}

.text-box:focus {
  @apply bg-white border-gray-500 outline-none;
}

.text-area {
  @apply block w-full h-auto py-2 px-4 text-gray-700 bg-gray-200 border border-gray-200 rounded;
}

.text-area:focus {
  @apply bg-white border-gray-500 outline-none;
}

.form-field-error {
  @apply block my-2 text-joy;
}

.form-label {
  @apply block text-gray-700 mb-2 font-semibold;
}

.form-label-light {
  @apply block text-gray-700 mb-2 font-semibold;
}

.form-checkbox {
  @apply h-6 w-6 flex items-center;
}

progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
  appearance: none;

  width: 250px;
  height: 20px;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

/* calendar */
.Calendar__day.-selected {
  @apply bg-joy text-offwhite;
}

@tailwind components;
@tailwind utilities;
